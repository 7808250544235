<template>
  <div class="flex justify-center z-[1] fixed w-full">
    <div class="shadow-md w-full bg-dark rounded-full">
      <div class="flex items-center justify-between bg-purple py-2 lg:px-4 px-2 lg:py-2.5 z-[5] ">

        <div class="font-semibold text-2xl cursor-pointer flex items-center gap-1">
          <span class="text-white text-sm">
            <a href="/" class="flex items-center justify-start gap-2 text-xs">
              <img src="../../assets/logo-branca.png" alt="Logotipo do Laboratório Ser" class="w-10 laptop:w-16" />
              Clínica de Psicologia Junguiana
            </a>
          </span>
        </div>

        <!-- Ícone do Menu -->
        <div @click="toggleMenu" class="cursor-pointer text-white text-2xl m-0 laptop:hidden">
          <font-awesome-icon :icon="isOpen ? ['fas', 'times'] : ['fas', 'bars']" />
        </div>

        <!-- Links do Menu -->
        <ul :class="[
            'font-medium text-sm flex-col lg:flex lg:flex-row lg:items-center lg:justify-between lg:pb-0 absolute lg:static bg-purple text-white lg:z-auto left-0 w-full lg:w-auto lg:pl-0 pl-3 transition-all duration-500 ease-in-out z-[-1]',
            isOpen ? 'top-[47px] opacity-100' : 'top-[-500px] opacity-0',
          ]" class="lg:opacity-100 lg:top-0">
          <li v-for="(link, index) in links" :key="index" class="lg:ml-5 lg:my-0 my-3">
            <a :href="link.link" class="text-white hover:text-blue duration-500 block lg:inline">
              {{ link.name }}
            </a>
          </li>
          <li class="lg:ml-5 lg:my-0 my-3 font-bold">
            <NuxtLink to="/"><ButtonHeader title="Home" /></NuxtLink>
          </li>
          <li class="lg:ml-5 lg:my-0 my-3 font-bold">
            <NuxtLink to="/blog"><ButtonHeader title="Blog" /></NuxtLink>
          </li>
          <li class="lg:ml-5 lg:my-0 my-3 font-bold">
            <NuxtLink to="/sobre-mim"><ButtonHeader title="Sobre mim" /></NuxtLink>
          </li>
          <li class="lg:ml-5 lg:my-0 my-3 font-bold">
            <NuxtLink to="/psicologas"><ButtonHeader title="Psicólogas" /></NuxtLink>
          </li>
          <li class="lg:ml-5 lg:my-0 my-3 font-bold">
            <NuxtLink to="/login"><ButtonHeader title="Paciente" /></NuxtLink>
          </li>
         
          <li class="lg:ml-5 lg:my-0 my-3 font-bold">
            <a :href="link"><ButtonHeader title="Administrativo" /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ButtonHeader from "./ButtonHeader.vue";

const runtimeConfigs = useRuntimeConfig()

export default {
  components: {
    FontAwesomeIcon,
    ButtonHeader,
  },
  setup() {
    const isOpen = ref(false);
    const link = ref(runtimeConfigs.public.API_URL)

    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
    };

    // const links = [
    //   { name: "Sobre", link: "#about-lab" },
    //   { name: "Equipe", link: "#about-us" },
    //   { name: "Benefícios", link: "#why-us" },
    //   { name: "Passos", link: "#steps" },
    //   { name: "Depoimentos", link: "#testimonials" },
    // ];

    const links = [];

    return {
      isOpen,
      toggleMenu,
      links,
      link
    };
  },
};
</script>

<style scoped>
/* Adicione estilos personalizados se necessário */
</style>
