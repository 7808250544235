<template>
  <div class="bg-texture bg-cover bg-no-repeat text-sm laptop:text-base">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
      <div class="md:flex items-center md:justify-start md:gap-12">
        <div class="mb-6 md:mb-0 w-1/2 mx-auto tablet:w-1/4 laptop:w-1/5">
          <a href="#">
            <img src="../../assets/logo.png" class="tablet:mx-0" alt="Logo do Laboratório do Ser" />
          </a>
        </div>
        <div
          class="grid grid-cols-1 text-center items-center gap-8 tablet:grid-cols-3 sm:gap-6 laptop:grid-cols-4 sm:text-start sm:items-start">
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Canais de Atendimento</h2>
            <ul class="text-gray-500 font-medium">
              <li class="mb-4">
                <p>Cidade - Estado</p>
                <p>Endereço</p>
              </li>
              <li class="mb-4">
                <p>(11) 93659-5463</p>
              </li>
              <li class="mb-4">
                <p>E-mail Comercial: lorem@gmail.com</p>
              </li>
              <li class="mb-4">
                <p>E-mail do Suporte: lorem@gmail.com</p>
              </li>
            </ul>
          </div>

          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Links Rápidos</h2>
            <ul class="text-gray-500 font-medium">
              <li class="mb-4">
                <a href="#" class="hover:underline">Home</a>
              </li>
              <li class="mb-4">
                <a href="#about-lab" class="hover:underline">Sobre Nós</a>
              </li>
              <li class="mb-4">
                <a href="#about-us" class="hover:underline">Nossa Equipe</a>
              </li>
              <li class="mb-4">
                <a href="#why-us" class="hover:underline">Benefícios</a>
              </li>
              <li class="mb-4">
                <a href="#steps" class="hover:underline">Próximos Passos</a>
              </li>
              <li class="mb-4">
                <a href="#testimonials" class="hover:underline">Depoimentos</a>
              </li>
              <li class="mb-4">
                <a href="/" class="hover:underline">Pacientes</a>
              </li>
              <li class="mb-4">
                <a href="/" class="hover:underline">Psicólogas</a>
              </li>
            </ul>
          </div>

          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Links Úteis</h2>
            <ul class="text-gray-500 font-medium">
              <li class="mb-4">
                <a href="#" class="hover:underline">Política de Privacidade</a>
              </li>
              <li>
                <NuxtLink to="/terms/of_use" class="hover:underline">Termos e Condições</NuxtLink>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <hr className="my-6 border-neutral-400 sm:mx-auto lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between text-center sm:text-start">
        <span className="text-xs text-gray-500 sm:text-center sm:text-sm ">© 2023 Cripsia Technology. Todos os direitos
          reservados.
        </span>
        <div class="flex mt-4 justify-center sm:mt-0">
          <!-- Facebook -->
          <a href="#" class="text-gray-500 hover:text-gray-900">
            <font-awesome-icon :icon="['fab', 'facebook']" class="w-4 h-4" />
            <span class="sr-only">Facebook page</span>
          </a>
          <!-- Discord -->
          <a href="#" class="text-gray-500 hover:text-gray-900 ms-5">
            <font-awesome-icon :icon="['fab', 'discord']" class="w-4 h-4" />
            <span class="sr-only">Discord community</span>
          </a>
          <!-- Twitter -->
          <a href="#" class="text-gray-500 hover:text-gray-900 ms-5">
            <font-awesome-icon :icon="['fab', 'twitter']" class="w-4 h-4" />
            <span class="sr-only">Twitter page</span>
          </a>
          <!-- GitHub -->
          <a href="#" class="text-gray-500 hover:text-gray-900 ms-5">
            <font-awesome-icon :icon="['fab', 'github']" class="w-4 h-4" />
            <span class="sr-only">GitHub account</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebook, faDiscord, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

// Adicionando os ícones à biblioteca
library.add(faFacebook, faDiscord, faTwitter, faGithub);


export default {
  name: "FooterSite",
  components: {
    FontAwesomeIcon,
  }
};
</script>

<style scoped>
/* Adicione aqui estilos específicos se necessário */
</style>
